/**
 * About Banner 3
 */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

class AboutBanner3 extends Component {

    constructor(){
        super();
        this.state = { showText: false };
      }
      getMoreTextDiv(){
        if (this.state.showText) {
            return  <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour.</p>;
          } else {
            return null;
          }
      }
   render() {
    var expandedDiv = this.getMoreTextDiv();
    return (
            <Row className="section-ptb">
            <div className="col-sm-12">
                <Row className="d-flex align-items-center">
                <Col sm={12} lg={7}>
                <div className="magasin_banner_wrapper">
                    <div className="magasin_banner magasin_banner-style-style-1 magasin_banner-effect-none banner-1">
                        <img className="magasin_banner-image img-fluid inline" alt="Banner" src={require(`../assets/images/sub-banner-01-1.png`)} />
                        <div className="magasin_banner-content magasin_banner-content-hcenter magasin_banner-content-vbottom ">
                            <div className="magasin_banner-content-wrapper ">
                                <div className="magasin_banner-content-inner-wrapper ">
                                    <div className="magasin_banner-text-wrap magasin_banner-text-wrap-1 magasin_banner-text-bg_color hidden-lg hidden-md hidden-sm hidden-xs ">
                                        <div className="magasin_banner-text bg-dark text-white px-3 px-sm-5 font-weight-bold">
                                        End of Season</div>
                                    </div>
                                    <div class="magasin_button_wrapper magasin_button_width_default mt-0">
                                        <div className="inline_hover magasin_button_default magasin_button_border_square magasin_button_size_medium"> <Link to="/shop" className="magasin_banner-btn ">Shop now</Link></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="magasin_banner-badge-wrap ">
                            <div className="magasin_banner-badge-inner-wrap ">
                                <div className="magasin_banner-badge magasin_banner-badge_style-style-1 magasin_banner-badge_type-flat magasin_banner-badge_align-vtop magasin_banner-badge_align-hleft ">
                                    <div className="magasin_banner-badge-inner "> <span className="magasin_banner-badge-text ">Sale</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </Col>
                <Col sm={12} lg={5} className="mt-4 mt-md-5 mt-lg-0">
                <div className="section-title mb-4">
                    <span className="banner-title">Know More</span>
                    <h2 className="title text-left"> About magasin</h2>
                    <p className="mb-0 mb-md-2">We are magasin ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veni quis nostrud exercitation ullamco laboris nisi ut aliquip
                    ex ea commodo consequat.</p>
                    { expandedDiv }
                </div>
                <div className="magasin_button_wrapper magasin_button_width_default ">
                    <div className="inline_hover magasin_button_default">
                    <Link className="banner-link"  onClick={() => this.setState({ showText: !this.state.showText }) }> {(this.state.showText) ? "Read Less.." : "Read More.."}   </Link>
                    </div>
                </div>
                </Col>
                </Row>
            </div>
            </Row>
      )
   }
}

export default AboutBanner3;
