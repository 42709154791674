/* Invoice Bill Json */
export default [
{
    "profile":
        {
            "firstname":"Alice",
            "lastname":"Williams",
            "gender":"Male",
            "dob":"2019-07-03",
            "phoneno":"1266322345",
            "email":"support@magasin.com",
            "address":"1635 Franklin Street Montgomery, Near Sherwood Mall. AL 36104",
            "companyname" : "Ciya Shop",
            "address":"4402 Worthington Drive",
            "streetno":"Richardson",
            "state":"Near Sherwood Mall",
            "zipcode":"361046",
            "country":"US",
            "phone":"5685486123"
        }
    ,
    "billingaddress":
        {
            "billingname":"magasin",
            "address":"4402 Worthington Drive",
            "streetno":"Richardson",
            "state":"Near Sherwood Mall",
            "zipcode":"361046",
            "country":"USA"
        }
    ,
    "shippingaddress":
        {   
            "shippingname":"magasin",
            "address":"4402 Worthington Drive",
            "streetno":"Richardson",
            "state":"Near Sherwood Mall",
            "zipcode":"361046",
            "country":"UK"
        }
    ,
    "carddetail":
        {
            "cardno": "8888-8888-8888-8888",
            "cardname":"Visa - Credit Card",
            "cvv":"001",
            "month":"12",
            "year":"2025"
            
        }
    
    }
]